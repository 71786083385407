import React from 'react'
import { graphql, Link } from 'gatsby'

import Carousel from '../../components/carousel'
import Layout from '../../components/layout'
import SEO from '../../components/seo'


export default ({ data }) => {
  const images = [
    { img: data.i1, caption: 'Bar Joist Roof on Infinity Wall Panels' },
    { img: data.i2, caption: 'Bar Joists & Steel framing at Roof Level' },
    { img: data.i3, caption: 'Bar Joist Roof on Infinity Wall Panels' },
    { img: data.i4, caption: 'Bottom Chord Bearing Bar Joists' },
    { img: data.i5, caption: 'Wood Roof Trusses on Infinity Wall Panels' },
    { img: data.i6, caption: 'Wood Roof Trusses on Infinity Wall Panels' },
    { img: data.i7, caption: 'Wood Roof Trusses on Infinity Wall Panels' },
  ];

  return (
    <Layout>
      <SEO title="System Overview: Roof Systems" keywords={[]}/>
      <div className="container system-overview-container">
        <h2 className='subtitle subtitle--xl color-primary'>System Overview</h2>
        <h1 className='title title--xxxl color-primary'>Roof Systems</h1>

        <div className='grid grid--gutterless'>
          <div className='grid__column grid__column--12 grid__column--4--md'>
            <p>Most mid-to-high-rise projects that utilize our Infinity Structural
            System have a flat roof. A flat roof may be constructed of either an Epicore
            MSR concrete roof slab, an EPIC long-span roof deck (no concrete), or
            standard bar joists with B-deck.  Lower-rise projects with a pitched roof
            generally use wood roof trusses since they are so much more economical than
            metal roof trusses.  If the code does not allow wood roof trusses for your
            particular project’s classification, metal roof trusses may be required.</p>
            <p>
              <Link className="link-prev" to="/system-overview/demising-walls/">
                Prev Page: Demising Walls / Tenant Separation Walls
              </Link>
            </p>
          </div>

          <div className='grid__column grid__column--12 grid__column--8--md'>
            <div className='carousel-container carousel-container--padding-x'>
              <Carousel
                content={images}
                carWidth={474}
                carHeight={316}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
    query {
        i1: file(relativePath: { eq: "system-overview/roof-systems/gallery/01-DeRen015_(40020).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i2: file(relativePath: { eq: "system-overview/roof-systems/gallery/02-Roofs050_(42970).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i3: file(relativePath: { eq: "system-overview/roof-systems/gallery/03-Roofs070_(42972).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i4: file(relativePath: { eq: "system-overview/roof-systems/gallery/04-Roofs140_(43325).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i5: file(relativePath: { eq: "system-overview/roof-systems/gallery/05-VisRe010_(33909).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i6: file(relativePath: { eq: "system-overview/roof-systems/gallery/06-Roofs030_(33933).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i7: file(relativePath: { eq: "system-overview/roof-systems/gallery/07-Roofs040_(34249).png" }) {
            ...SystemOverviewCarouselImageFragment },
    }
`
